<template>
  <section>
    <t-form ref="form" @submit.prevent>
      <ExtendedDwellingTypeFormPart @result="onResult" />
    </t-form>
  </section>
</template>

<script>
import AbstractFormStep from 'chimera/all/components/form/steps/AbstractFormStep'
import ExtendedDwellingTypeFormPart from 'chimera/realEstateAgency/components/form/parts/dwellingType/ExtendedDwellingTypeFormPart'
import PostalPage from '~/pages/offertes-aanvragen/postcode'

export default {
  name: 'DwellingTypeFormStep',

  components: {
    ExtendedDwellingTypeFormPart,
  },

  extends: AbstractFormStep,

  data: () => ({
    willTransitionOnValid: true,
  }),

  methods: {
    /**
     * Transition
     */
    transition() {
      this.routeTo(PostalPage)
    },
  },
}
</script>
