<template>
  <section>
    <t-form ref="form" @submit="onSubmit" @submit.prevent>
      <PostalFormPart :autofocus="true" @result="onResult" @change="onChange" />
      <FormStepSubmitButton id="submitPostal" />
    </t-form>
  </section>
</template>

<script>
import AbstractFormStep from 'chimera/all/components/form/steps/AbstractFormStep'
import PostalFormPart from 'chimera/realEstateAgency/components/form/parts/postal/PostalFormPart'
import PriceRangePage from '~/pages/offertes-aanvragen/prijsklasse'

export default {
  name: 'PostalFormStep',

  components: {
    PostalFormPart,
  },

  extends: AbstractFormStep,

  methods: {
    /**
     * Transition
     */
    transition() {
      this.routeTo(PriceRangePage)
    },
  },
}
</script>
