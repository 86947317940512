<template>
  <CTAContainer :title="$t('title')">
    <UspRow class="mb-4" />
    <ButtonColumnFormPart
      :buttons="choices"
      @click="onClick"
      v-on="$listeners"
    />
    <ServiceField
      ref="service"
      hidden
      :is-remembered="false"
      v-on="$listeners"
    />
  </CTAContainer>
</template>

<script>
import ButtonColumnFormPart from 'chimera/all/components/form/parts/ButtonColumnFormPart.vue'
import ServiceField from 'chimera/all/components/form/fields/service/ServiceField'
import CTAContainer from 'chimera/all/components/CTAContainer'
import UspRow from 'chimera/all/components/UspRow'
import {
  realEstateAppraisalConsumerBE,
  realEstateSalesConsumerBE,
} from 'chimera/realEstateAgency/service'
import { Selectable } from 'chimera/all/components/models/Selectable'

export default {
  name: 'AppraisalSalesServiceFormPart',

  components: {
    ButtonColumnFormPart,
    CTAContainer,
    UspRow,
    ServiceField,
  },

  /**
   * @returns {object}
   */
  data() {
    return {
      choices: [
        new Selectable(
          realEstateSalesConsumerBE.identifier,
          this.$i18n.t('salesText'),
          realEstateSalesConsumerBE.id,
        ),
        new Selectable(
          realEstateAppraisalConsumerBE.identifier,
          this.$i18n.t('appraisalText'),
          realEstateAppraisalConsumerBE.id,
        ),
      ],
    }
  },

  methods: {
    /**
     * @param selection
     */
    onClick(selection) {
      this.$refs.service.setValue(selection.value)
    },
  },
}
</script>

<i18n>
{
  "nl-NL": {
    "title": "Ontvang en vergelijk offertes voordat je beslist",
    "appraisalText": "Ik wil een woning laten taxeren",
    "salesText": "Ik wil een woning verkopen"
  },
  "nl-BE": {
    "title": "Ontvang en vergelijk offertes voordat u beslist",
    "appraisalText": "Ik wil een eigendom laten schatten",
    "salesText": "Ik wil een eigendom verkopen"
  }
}
</i18n>
