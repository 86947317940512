<template>
  <AbstractFormPart :title="$t('title')">
    <DwellingTypeField :choices="choices" v-on="$listeners" />
  </AbstractFormPart>
</template>

<script>
import DwellingTypeField from 'chimera/all/components/form/fields/dwellingType/DwellingTypeField'
import { Selectable } from 'chimera/all/components/models/Selectable'

export default {
  name: 'ExtendedDwellingTypeFormPart',

  components: {
    DwellingTypeField,
  },

  /**
   * @returns {object}
   */
  data() {
    return {
      choices: [
        new Selectable('Woonhuis', 'Woonhuis', 'Woonhuis'),
        new Selectable('Appartement', 'Appartement', 'Appartement'),
        new Selectable('Anders', 'Anders', 'Anders'),
      ],
    }
  },
}
</script>

<i18n>
{
  "nl-NL": {
    "title": "Om wat voor type woning gaat het?"
  },
  "nl-BE": {
    "title": "Om wat voor type eigendom gaat het?"
  }
}
</i18n>
