<template>
  <section>
    <t-form ref="form" @submit.prevent>
      <AppraisalSalesServiceFormPart @result="onResult" />
    </t-form>
  </section>
</template>

<script>
import AbstractFormStep from 'chimera/all/components/form/steps/AbstractFormStep'
import AppraisalSalesServiceFormPart from 'chimera/realEstateAgency/components/form/parts/service/AppraisalSalesServiceFormPart'
import transitionsHelper from 'chimera/realEstateAgency/mixins/transitionsHelper'
import DwellingTypePage from '~/pages/offertes-aanvragen/type-woning'

export default {
  name: 'ServiceFormStep',

  components: {
    AppraisalSalesServiceFormPart,
  },

  extends: AbstractFormStep,

  mixins: [transitionsHelper],

  data: () => ({
    willTransitionOnValid: true,
  }),

  methods: {
    /**
     * Transition
     */
    transition() {
      this.routeTo(DwellingTypePage)
    },
  },
}
</script>
