<template>
  <div>
    <DwellingTypeFormStep :progress-value="progressValue" />
  </div>
</template>

<script>
import DwellingTypeFormStep from '~/components/form/steps/dwellingType/DwellingTypeFormStep'
import FormPage from '~/components/form/FormPage'

export default {
  name: 'DwellingTypePage',

  components: {
    DwellingTypeFormStep,
  },

  extends: FormPage,

  /**
   * @returns {object}
   */
  data() {
    return {
      headTitle: 'Type woning',
      path: '/offertes-aanvragen/type-woning',
      progressValue: 20,
    }
  },
}
</script>
