<template>
  <div>
    <AppraisalPostalFormStep
      v-if="serviceIsAppraisal()"
      :progress-value="progressValue"
    />
    <PostalFormStep
      v-else-if="serviceIsSales()"
      :progress-value="progressValue"
    />
    <RedirectFormPart v-else />
  </div>
</template>

<script>
import RedirectFormPart from 'chimera/all/components/form/parts/RedirectFormPart'
import PostalFormStep from '~/components/form/steps/postal/PostalFormStep'
import FormPage from '~/components/form/FormPage'
import AppraisalPostalFormStep from '~/components/form/steps/postal/AppraisalPostalFormStep'

export default {
  name: 'PostalPage',

  components: {
    AppraisalPostalFormStep,
    PostalFormStep,
    RedirectFormPart,
  },

  extends: FormPage,

  /**
   * @returns {object}
   */
  data() {
    return {
      headTitle: 'Postcode woning',
      path: '/offertes-aanvragen/postcode',
      progressValue: 40,
    }
  },
}
</script>
