<template>
  <section>
    <t-form ref="form" @submit="onSubmit" @submit.prevent>
      <AppraisalPostalFormPart
        :autofocus="true"
        @result="onResult"
        @change="onChange"
      />
      <FormStepSubmitButton id="submitPostal" />
    </t-form>
  </section>
</template>

<script>
import AbstractFormStep from 'chimera/all/components/form/steps/AbstractFormStep'
import AppraisalPostalFormPart from 'chimera/realEstateAgency/components/form/parts/postal/AppraisalPostalFormPart'
import PriceRangePage from '~/pages/offertes-aanvragen/prijsklasse'

export default {
  name: 'AppraisalPostalFormStep',

  components: {
    AppraisalPostalFormPart,
  },

  extends: AbstractFormStep,

  methods: {
    /**
     * Transition
     */
    transition() {
      this.routeTo(PriceRangePage)
    },
  },
}
</script>
