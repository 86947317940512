<template>
  <AbstractFormPart :title="question">
    <PostalField :autocomplete="autocomplete" v-on="$listeners" />
  </AbstractFormPart>
</template>

<script>
import { field as dwellingTypeField } from 'chimera/all/components/form/fields/dwellingType/DwellingTypeField'
import PostalField from 'chimera/all/components/form/fields/postal/PostalField'

export default {
  name: 'PostalFormPart',

  components: {
    PostalField,
  },

  props: {
    autocomplete: {
      type: String,
      default: 'postal-code',
    },
  },

  computed: {
    /**
     * @returns {string}
     */
    question() {
      let dwellingType = this.$store.getters['lead/getData'](
        dwellingTypeField,
        'woning',
      )
      if (dwellingType.toLowerCase() === 'anders') {
        dwellingType = 'eigendom'
      }
      return this.$i18n.t('title', { dwellingType: dwellingType.toLowerCase() })
    },
  },
}
</script>

<i18n>
{
  "nl-NL": {
    "title": "Wat is de postcode van het te verkopen {dwellingType}?"
  },
  "nl-BE": {
    "title": "Wat is de postcode van het te verkopen {dwellingType}?"
  }
}
</i18n>
